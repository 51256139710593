import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: { color: '#333331' },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer} {...props}>
      © {new Date().getFullYear()}, Made with ❤️ and a 💻
    </footer>
  );
};

export default Footer;
