import { createMuiTheme } from '@material-ui/core';

// #5680E9
// #84CEEB
// #5AB9EA
// #CAC8E4
// #8860D0

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#5680E9',
    },
    secondary: {
      main: '#8860D0',
    },
  },
});
