import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { makeStyles, MuiThemeProvider } from '@material-ui/core';
import Header from './header';
import '../fonts/LatoLatin/latolatinfonts.css';
import './layout.css';
import Footer from './footer';
import { theme } from '../style/theme';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    margin: '0 auto',
    maxWidth: 960,
    padding: '0 1.0875rem 1.45rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  main: {
    maxWidth: '100%',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '10px',
    // background: 'rgba(0,0,0,0.75)',
    color: 'white',
  },
  footer: {
    textAlign: 'center',
    fontSize: '.7em',
  },
  grow: { flexGrow: 1 },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <div className={classes.grow} />
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className={classes.main}>{children}</main>
          <div className={classes.grow} />
          <Footer className={classes.footer} />
        </div>
      </MuiThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
