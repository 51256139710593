import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import SvgLogo from '../images/logo_light.png';

const useStyles = makeStyles((theme) => ({
  header: {},
}));

const Header = ({ siteTitle }) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div
        css={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '1.45rem 1.0875rem',
        }}
      >
        <h1 css={{ margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Link
            to="/"
            css={{
              color: 'white',
              textDecoration: 'none',
              display: 'inline-flex',
              padding: '.25rem 1rem',
            }}
          >
            <img
              src={SvgLogo}
              alt="UWIZY logo"
              css={{ borderRadius: '10px', width: '200px', margin: '0', maxwidth: '80%' }}
            />
          </Link>
          <h6 css={{ textAlign: 'center', fontStyle: 'italic', color: '#333331' }}>Focus on what matters</h6>
        </h1>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
